import { ReactElement } from 'react';

import texts from '^configs/texts';
import CheckmarkIcon from '^icons/CheckmarkIcon';
import dataQa from '^configs/dataQa';

import ResponsePageTemplate from '../ResponsePageTemplate';

import styles from './styles.module.scss';

const SuccessPage = (): ReactElement => (
  <ResponsePageTemplate
    className={styles.successPage}
    Logo={<CheckmarkIcon className={styles.icon} />}
    logoWrapperClassName={styles.iconWrapper}
    title={texts.common.successPage.title}
    description={texts.common.successPage.description}
    dataQaTitle={dataQa.success.title}
    dataQaLogo={dataQa.success.logo}
    dataQaDescription={dataQa.success.description}
  />
);

export default SuccessPage;
