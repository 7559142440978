import { ReactElement, ReactNode, useEffect } from 'react';
import { useDeviceType } from '@interdan/public-ui';

import styles from './styles.module.scss';

export interface IWidgetLayoutProviderProps {
  children: ReactNode;
}

const WidgetLayoutProvider = ({ children }: IWidgetLayoutProviderProps): ReactElement => {
  const { isDesktop } = useDeviceType();

  useEffect(
    (): (() => void) | void => {
      if (window.xprops.isWidgetMode) {
        document.documentElement.classList.add(styles.widgetLayout);
      }

      if (isDesktop) {
        return;
      }

      void window.xprops.autoCalculateSpacers();
    },
    [isDesktop],
  );

  return children as ReactElement; // Update if https://github.com/yannickcr/eslint-plugin-react/pull/2967 is merged
};

export default WidgetLayoutProvider;
