import { ReactElement, ReactNode } from 'react';

import PageSkeleton from '^components/PageSkeleton';

import useRequestStores from './useRequestStores';

interface IStoresProviderProps {
  children: ReactNode;
}

const StoresProvider = ({ children }: IStoresProviderProps): ReactElement => {
  const isLoaded = useRequestStores();

  if (!isLoaded) {
    return (
      <PageSkeleton />
    );
  }

  return children as ReactElement;
};

export default StoresProvider;
