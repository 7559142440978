/* eslint-disable max-lines */
import { Observable, WritableObservable, observable } from 'micro-observables';

import Feature from '^enums/Feature';
import IAccessory from '^types/accessory/IAccessory';
import ICar from '^types/car/ICar';
import IGlobalService from '^types/service/IGlobalService';
import ILocalService from '^types/service/ILocalService';
import IStore from '^types/store/IStore';
import ICustomer from '^types/customer/ICustomer';
import IConfigurationValues from '^types/order/IConfigurationValues';

export type TCar = ICar | null;
export type TStoreCode = IStore['code'] | null;
export type TFeature = Feature | null;
export type TCustomService = string | null;

const DEFAULT_CUSTOMER: ICustomer = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  zip: '',
  city: '',
  address: '',
};

class ConfigurationService {
  private _car = observable<TCar>(null);

  private _storeCode = observable<TStoreCode>(null);
  private _isPredefinedStoreCode: WritableObservable<boolean> = observable<boolean>(false);

  private _globalServiceIds = observable<IGlobalService['id'][]>([]);
  private _localServiceIds = observable<ILocalService['id'][]>([]);
  private _customService = observable<TCustomService>(null);

  private _aftermarketAccessoryIds = observable<IAccessory['id'][]>([]);
  private _globalAccessoryIds = observable<IAccessory['id'][]>([]);
  private _localAccessoryIds = observable<IAccessory['id'][]>([]);

  private _customer = observable<ICustomer>(DEFAULT_CUSTOMER);

  private _feature = observable<TFeature>(null);
  private _date = observable<string>('');
  private _timeSlot = observable<string>('');

  private _isPrebooking = observable<boolean>(false);

  public car = this._car.readOnly();

  public storeCode = this._storeCode.readOnly();

  public globalServiceIds = this._globalServiceIds.readOnly();
  public localServiceIds = this._localServiceIds.readOnly();
  public customService = this._customService.readOnly();

  public aftermarketAccessoryIds = this._aftermarketAccessoryIds.readOnly();
  public globalAccessoryIds = this._globalAccessoryIds.readOnly();
  public localAccessoryIds = this._localAccessoryIds.readOnly();

  public customer = this._customer.readOnly();

  public feature = this._feature.readOnly();
  public date = this._date.readOnly();
  public timeSlot = this._timeSlot.readOnly();

  public isPrebooking = this._isPrebooking.readOnly();

  public accessoryCount = Observable.select(
    [this.aftermarketAccessoryIds, this.globalAccessoryIds, this.localAccessoryIds],
    (
      aftermarketAccessoryIds: number[],
      globalAccessoryIds: number[],
      localAccessoryIds: number[],
    ): number =>
      aftermarketAccessoryIds.length + globalAccessoryIds.length + localAccessoryIds.length,
  );

  public bookingValues = Observable.select(
    [
      this._globalServiceIds,
      this._localServiceIds,
      this._customService,
      this._aftermarketAccessoryIds,
      this._globalAccessoryIds,
      this._localAccessoryIds,
      this._customer,
      this._isPrebooking,
      this._car,
      this._storeCode,
      this._feature,
      this._date,
      this._timeSlot,
    ],
    (
      globalServiceIds: IGlobalService['id'][], localServiceIds: ILocalService['id'][], customService: TCustomService,
      aftermarketAccessoryIds: IAccessory['id'][], globalAccessoryIds: IAccessory['id'][], localAccessoryIds: IAccessory['id'][],
      customer: ICustomer,
      isPrebooking: boolean,
      car: TCar,
      storeCode: TStoreCode,
      feature: TFeature,
      date: string,
      timeSlot: string,
    ): IConfigurationValues => ({
      globalServiceIds: globalServiceIds as number[],
      localServiceIds,
      customService,
      customer,
      aftermarketAccessoryIds,
      globalAccessoryIds,
      localAccessoryIds,
      isPrebooking,
      car: car as ICar,
      storeCode: storeCode as IStore['code'],
      feature: feature as Feature,
      date,
      timeSlot,
    }),
  );

  public isPredefinedStoreCode = this._isPredefinedStoreCode.readOnly();

  public setIsPredefinedStoreCode(value: boolean): void {
    this._isPredefinedStoreCode.set(value);
  }

  public setCar(value: NonNullable<TCar>): void {
    this._car.set(value);
  }

  public resetCar(): void {
    this._car.set(null);
  }

  public setStoreCode(value: NonNullable<TStoreCode>): void {
    this._storeCode.set(value);
  }

  public resetStoreCode = (): void => {
    if (this.isPredefinedStoreCode.get()) {
      return;
    }

    this._storeCode.set(null);
  };

  public setGlobalServicesIds(ids: IGlobalService['id'][]): void {
    this._globalServiceIds.set(ids);
  }

  public setLocalServicesIds(ids: ILocalService['id'][]): void {
    this._localServiceIds.set(ids);
  }

  public resetServicesIds(): void {
    this._globalServiceIds.set([]);
    this._localServiceIds.set([]);
  }

  public setAftermarketAccessoryIds(ids: IAccessory['id'][]): void {
    this._aftermarketAccessoryIds.set(ids);
  }

  public setGlobalAccessoryIds(ids: IAccessory['id'][]): void {
    this._globalAccessoryIds.set(ids);
  }

  public setLocalAccessoryIds(ids: IAccessory['id'][]): void {
    this._localAccessoryIds.set(ids);
  }

  public resetAccessoryIds(): void {
    this._aftermarketAccessoryIds.set([]);
    this._globalAccessoryIds.set([]);
    this._localAccessoryIds.set([]);
  }

  public setFeature(value: Feature): void {
    this._feature.set(value);
  }

  public resetFeature(): void {
    this._feature.set(null);
  }

  public setCustomService(value: string | null): void {
    this._customService.set(value);
  }

  public resetCustomService(): void {
    this._customService.set('');
  }

  public setCustomer(value: ICustomer): void {
    this._customer.set(value);
  }

  public resetCustomer(): void {
    this._customer.set(DEFAULT_CUSTOMER);
  }

  public setIsPrebooking(value: boolean): void {
    this._isPrebooking.set(value);
  }

  public setDate(value: string): void {
    this._date.set(value);
  }

  public setTimeSlot(value: string): void {
    this._timeSlot.set(value);
  }

  public resetDate(): void {
    this._date.set('');
  }

  public resetTimeSlot(): void {
    this._timeSlot.set('');
  }
}

export default new ConfigurationService();
