import { ReactElement, ReactNode } from 'react';
import ContentLoader, { IContentLoaderProps } from 'react-content-loader';
import classNames from 'classnames';

import { DEFAULT_SKELETON_ANIMATION_SPEED } from '^configs/contentLoader';
import ContentWrapper from '^components/ContentWrapper';

import styles from './styles.module.scss';

export interface ISkeletonBaseProps extends IContentLoaderProps {}

interface ISkeletonBaseFinalProps extends ISkeletonBaseProps {
  children: ReactNode | ReactNode[];
}

const SkeletonBase = ({ className, children, ...rest }: ISkeletonBaseFinalProps): ReactElement => (
  <ContentWrapper className={classNames(styles.skeleton, className)}>
    <ContentLoader
      className={styles.skeletonBase}
      speed={DEFAULT_SKELETON_ANIMATION_SPEED}
      backgroundColor="currentColor"
      {...rest}
    >
      {children}
    </ContentLoader>
  </ContentWrapper>
);

export default SkeletonBase;
