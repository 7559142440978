import { Action, Location, createMemoryHistory, createBrowserHistory } from 'history';

import gtmHelper from '^utils/gtmHelper';

const sharedHistory = window.xprops.isWidgetMode ? createMemoryHistory() : createBrowserHistory();

sharedHistory.listen(({ pathname }: Location, action: Action): Promise<void> | void => {
  gtmHelper.emitPageView(pathname);

  if (action !== 'REPLACE') {
    void window.xprops.scrollToWidget();
  }
});

export default sharedHistory;
