import { stringify } from 'query-string';

import ErrorReason from '^enums/ErrorReason';
import RouteUrl from '^enums/routing/RouteUrl';
import sharedHistory from '^sharedHistory';

export const getErrorRoutePath = (): string => RouteUrl.Error;

export const getSuccessRoutePath = (): string => RouteUrl.Success;

export const goToErrorRoute = (url: string = getErrorRoutePath()): void => {
  sharedHistory.push(url);
};

export const goToSuccessRoute = (url: string = getSuccessRoutePath()): void => {
  sharedHistory.push(url);
};

export interface IEntryPointPageRouteParams {
  plateNumber?: string;
}

export const getEntryPointPageRoutePath = (): string => (
  `${RouteUrl.EntryPoint}`
);

export const getEntryPointPageRouteUrl = ({ plateNumber }: IEntryPointPageRouteParams): string => `${getEntryPointPageRoutePath()}/?${stringify({
  plateNumber,
})}`;

export const goToEntryPointPageRoute = (): void => (
  sharedHistory.push(getEntryPointPageRoutePath())
);

export const getMainFlowPageRoutePath = (): string => (
  `${RouteUrl.MainFlow}`
);

export const goToMainFlowPageRoute = (): void => (
  sharedHistory.push(getMainFlowPageRoutePath())
);

export interface ISupportPageRouteParams {
  reason: ErrorReason;
  isBackLinkDisabled?: boolean;
}

export const getSupportPageRoutePath = (): string => (
  `${RouteUrl.Support}`
);

export const getSupportPageRouteUrl = ({ reason, isBackLinkDisabled }: ISupportPageRouteParams): string => (
  `${getSupportPageRoutePath()}/?${stringify({
    reason,
    isBackLinkDisabled,
  })}`
);

export const goToSupportPageRoute = (params: ISupportPageRouteParams): void => (
  sharedHistory.push(getSupportPageRouteUrl(params))
);
