enum GTMEventAction {
  Change = 'change',
  Submit = 'submit',
  Edit = 'edit',
  Click = 'click',
  Reset = 'reset',
  NoResults = 'no-results',
  Error = 'error',
  Add = 'add',
  Remove = 'remove',
  Select = 'select',
  Give = 'give',
  Revoke = 'revoke',
  Open = 'open',
  Close = 'close',
}

export default GTMEventAction;
