import { ReactElement } from 'react';

import GlobalConfig from '^common/GlobalConfig';
import StoresProvider from '^providers/StoresProvider';

import RoutingRules from './RoutingRules';

const App = (): ReactElement => (
  <GlobalConfig>
    <StoresProvider>
      <RoutingRules />
    </StoresProvider>
  </GlobalConfig>
);

export default App;
