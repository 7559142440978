import TagManager from 'react-gtm-module';

import { GTM_ID } from '^configs/environments';
import IGTMEvent from '^types/gtm/IGTMEvent';
import GTMEventCategory from '^enums/gtm/GTMEventCategory';
import IGTMDimension from '^types/gtm/IGTMDimension';
import TGTMDataLayer from '^types/gtm/TGTMDataLayer';

enum Event {
  Default = 'GAEvent',
  CustomDimensionsUpdate = 'CustomDimensionsUpdate',
  Pageview = 'pageview',
}

const APPLICATION_NAME = 'ServiceBooker';
const defaultDimension: IGTMDimension = {
  utmSource: window.xprops.utmSource,
  utmMedium: window.xprops.utmMedium,
  utmCampaign: window.xprops.utmCampaign,
  trackingClickId: window.xprops.trackingClickId,
  trackingId: window.xprops.trackingId,
  originalLocation: window.location.href,
  applicationName: APPLICATION_NAME,
};

class GTMHelper {
  private currentDimension: Partial<IGTMDimension> = defaultDimension;

  public init = (): void => {
    if (!GTM_ID) {
      return;
    }

    TagManager.initialize({
      gtmId: GTM_ID,
    });

    this.updateDimension(defaultDimension);
  };

  private emitGTMEvent = (dataLayer: TGTMDataLayer): void => {
    TagManager.dataLayer({
      dataLayer,
    });
  };

  public updateDimension = (dimension: Partial<IGTMDimension>): void => {
    this.currentDimension = {
      ...this.currentDimension,
      ...dimension,
    };

    this.emitGTMEvent({
      event: Event.CustomDimensionsUpdate,
      ...this.currentDimension,
    } as IGTMDimension);
  };

  public emitEvent = (category: GTMEventCategory, action: string, label: string = ''): void => {
    const dataLayer: IGTMEvent = {
      event: Event.Default,
      eventLabel: label,
      eventCategory: category,
      eventAction: action,
      ...this.currentDimension,
    };

    this.emitGTMEvent(dataLayer);
  };

  public emitPageView = (path: string): void => {
    this.emitGTMEvent({
      event: Event.Pageview,
      VirtualPageviewPath: path,
      ...this.currentDimension,
    } as TGTMDataLayer);
  };
}

export default new GTMHelper();
