import { Validate } from 'react-hook-form';

import texts from '^configs/texts';

export const PHONE_NUMBER_LENGTH = 8;

export const MAX_ODOMETER_VALUE = 999999;
export const BASE_TEXT_FIELD_MAX_CHARS = 50;
export const MAX_EMAIL_LENGTH = 320;
export const MAX_MESSAGE_LENGTH = 300;
export const MAX_ADDRESS_LENGTH = 150;
export const ZIP_LENGTH = 4;

export const required = (value: string): string | undefined => value ? undefined : texts.validationErrors.required;

export const min = (minValue: number) => (value: string): string | undefined => {
  if (!value) {
    return undefined;
  }

  const numberValue = Number(value);

  return numberValue >= minValue ? undefined : texts.validationErrors.min(minValue);
};

export const max = (maxValue: number) => (value: string): string | undefined => {
  if (!value) {
    return undefined;
  }

  const numberValue = Number(value);

  return numberValue <= maxValue ? undefined : texts.validationErrors.max(maxValue);
};

export const maxChars = (maxCharsLength: number) => (value: string): string | undefined => {
  if (!value) {
    return undefined;
  }

  return value.length <= maxCharsLength ? undefined : texts.validationErrors.maxChars(maxCharsLength);
};

export const plateNumber = (value: string): string | undefined => {
  if (!value) {
    return undefined;
  }

  return /^[A-Za-z0-9]{2,7}$/.test(value) ? undefined : texts.validationErrors.plateNumber;
};

export const phone = (value: string): string | undefined => {
  const message = texts.validationErrors.phone;

  if (!value) {
    return undefined;
  }

  return value.length === PHONE_NUMBER_LENGTH ? undefined : message;
};

export const email = (value: string = ''): string | undefined => {
  const message = texts.validationErrors.email;

  const patternRegex = /^(([^<>()[\]\\.,;:\s@"]{1,64}(\.[^<>()[\]\\.,;:\s@"]+)*)|(".{1,64}"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const asciiRegex = /^[\x20-\x7F]*$/;
  /**
   * Using a positive lookahead assertion, we can test a value against both patterns in a single pass.
   */
  const combinedRegex= new RegExp(`^(?=${patternRegex.source})(?=${asciiRegex.source})`);

  return combinedRegex.test(value) ? undefined : message;
};

export const isZipValid = (value: string = ''): boolean => value.length === ZIP_LENGTH;

export const zip = (value: string): string | undefined => {
  if (!value) {
    return undefined;
  }

  return isZipValid(value) ? undefined : texts.validationErrors.zip;
};

export const phoneValidation: Record<string, Validate> = {
  required,
  phone,
};

export const emailValidation: Record<string, Validate> = {
  required,
  email,
  maxChars: maxChars(MAX_EMAIL_LENGTH),
};

export const baseTextFieldValidation: Record<string, Validate> = {
  required,
  maxChars: maxChars(BASE_TEXT_FIELD_MAX_CHARS),
};

export const zipValidation: Record<string, Validate> = {
  required,
  zip,
};

export const addressValidation: Record<string, Validate> = {
  required,
  maxChars: maxChars(MAX_ADDRESS_LENGTH),
};

