import { ReactElement } from 'react';

import texts from '^configs/texts';
import ErrorIcon from '^icons/ErrorIcon';

import ResponsePageTemplate from '../ResponsePageTemplate';

import styles from './styles.module.scss';

const ErrorPage = (): ReactElement => (
  <ResponsePageTemplate
    className={styles.errorPage}
    Logo={<ErrorIcon className={styles.icon} />}
    logoWrapperClassName={styles.iconWrapper}
    title={texts.common.errorPage.title}
    description={texts.common.errorPage.description}
  />
);

export default ErrorPage;
