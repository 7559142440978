import React, { ReactElement, useCallback, useState } from 'react';
import { Validate } from 'react-hook-form/dist/types/validator';
import { Input, Button, FormProvider, FormField, BackLink, IInputProps } from '@interdan/public-ui';

import ISupportRequest from '^types/support/ISupportRequest';
import AutoCompleteValue from '^enums/AutoCompleteValue';
import texts from '^configs/texts';
import { MAX_MESSAGE_ROWS } from '^configs/common';
import { phoneNumberMask } from '^utils/masks';
import { createSupportRequest } from '^api/supportRequests';
import PageSkeleton from '^components/PageSkeleton';
import {
  goToSuccessRoute,
  goToErrorRoute,
  goToEntryPointPageRoute, ISupportPageRouteParams,
} from '^utils/routingHelpers';
import logger from '^utils/logger';
import {
  maxChars,
  MAX_MESSAGE_LENGTH,
  baseTextFieldValidation,
  phoneValidation,
  emailValidation,
} from '^utils/validators';
import useQueryParams from '^hooks/useQueryParams';
import { emitSupportFormSubmissionFailed, emitSupportFormSubmit } from '^utils/gtmEvents';
import dataQa from '^configs/dataQa';
import ContentWrapper from '^components/ContentWrapper';

import Status from './Status';
import PermissionNotice from './PermissionNotice';
import styles from './styles.module.scss';

const customMaskConfig: IInputProps['customMaskConfig'] = {
  mask: phoneNumberMask,
  lazy: false,
};

const messageValidation: Record<string, Validate> = {
  maxChars: maxChars(MAX_MESSAGE_LENGTH),
};

const { privacyPolicy, personalDataLink } = texts.supportPage.permissionNotice;
const { privacyPolicyUrl } = window.xprops;
const permissionText = `${privacyPolicy()} ${personalDataLink} (${privacyPolicyUrl})`;

const SupportPage = (): ReactElement => {
  const { reason, isBackLinkDisabled } = useQueryParams<ISupportPageRouteParams>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleSubmit = useCallback(
    async (values: ISupportRequest): Promise<void> => {
      try {
        void window.xprops.scrollToWidget();

        setIsSubmitting(true);
        await createSupportRequest({
          reason,
          ...values,
          permissionText,
        });

        emitSupportFormSubmit(reason);

        goToSuccessRoute();
      } catch (error) {
        goToErrorRoute();

        emitSupportFormSubmissionFailed();

        logger.trackError(error, {
          catcher: 'SupportPage.handleSubmit',
        });
      } finally {
        setIsSubmitting(false);
      }
    },
    [reason],
  );

  if (isSubmitting) {
    return (
      <PageSkeleton />
    );
  }

  return (
    <ContentWrapper>
      <div className={styles.supportPage}>
        {!isBackLinkDisabled && (
          <BackLink
            label={texts.common.goBack}
            onClick={goToEntryPointPageRoute}
            className={styles.backButton}
            data-qa={dataQa.goBack}
          />
        )}

        <FormProvider
          onSubmit={handleSubmit}
          className={styles.form}
        >
          <Status
            reason={reason}
            className={styles.status}
            dataQa={dataQa.support.warning}
          />

          <div className={styles.section}>
            <div className={styles.fieldRow}>
              <FormField
                name="customer.firstName"
                label={texts.customer.firstName}
                type="text"
                Target={Input}
                validate={baseTextFieldValidation}
                data-qa={dataQa.support.name}
                autoComplete={AutoCompleteValue.FirstName}
              />

              <FormField
                name="customer.lastName"
                label={texts.customer.lastName}
                type="text"
                Target={Input}
                validate={baseTextFieldValidation}
                autoComplete={AutoCompleteValue.LastName}
                data-qa={dataQa.support.surname}
              />
            </div>

            <div className={styles.fieldRow}>
              <FormField
                name="customer.phone"
                label={texts.customer.phone}
                type="tel"
                mask={phoneNumberMask}
                Target={Input}
                validate={phoneValidation}
                shrinkLabel
                customMaskConfig={customMaskConfig}
                autoComplete={AutoCompleteValue.Phone}
                data-qa={dataQa.support.phone}
              />

              <FormField
                name="customer.email"
                label={texts.customer.email}
                type="text"
                Target={Input}
                validate={emailValidation}
                autoComplete={AutoCompleteValue.Email}
                data-qa={dataQa.support.email}
              />
            </div>

            <div className={styles.fieldRow}>
              <FormField
                name="message"
                isMultiline
                maxRows={MAX_MESSAGE_ROWS}
                label={texts.supportPage.message}
                type="text"
                Target={Input}
                validate={messageValidation}
                data-qa={dataQa.support.message}
              />
            </div>
          </div>

          <PermissionNotice className={styles.permission} />

          <Button
            className={styles.submit}
            type="submit"
            data-qa={dataQa.support.submit}
          >
            {texts.actions.submit}
          </Button>
        </FormProvider>
      </div>
    </ContentWrapper>
  );
};

export default SupportPage;
