import { ReactElement, ReactNode, useMemo } from 'react';
import { GlobalConfig as GlobalConfigBase, ICloudinaryConfig } from '@interdan/public-ui';

import WidgetLayoutProvider from '^common/WidgetLayoutProvider';
import ErrorBoundary from '^common/ErrorBoundary';
import { CLOUD_NAME } from '^configs/environments';
import Logger from '^utils/logger';

import styles from './styles.module.scss';

export interface IGlobalConfigProps {
  children: ReactNode;
}

const handleCloudinaryImageError = (src: string): void => {
  Logger.trackWarning(`Image (${src}) is not found`, {
    catcher: 'OptimizedImage.error',
  });
};

const cloudinaryConfig: ICloudinaryConfig = {
  cloudName: CLOUD_NAME,
  handleError: handleCloudinaryImageError,
};

const GlobalConfig = ({ children }: IGlobalConfigProps): ReactElement => {
  const theme = useMemo(
    (): string => {
      if (window.xprops.theme) {
        return styles[window.xprops.theme] || window.xprops.theme;
      }

      return styles.default;
    },
    [],
  );

  return (
    <GlobalConfigBase
      theme={theme}
      cloudinaryConfig={cloudinaryConfig}
    >
      <ErrorBoundary>
        <WidgetLayoutProvider>
          {children}
        </WidgetLayoutProvider>
      </ErrorBoundary>
    </GlobalConfigBase>
  );
};

export default GlobalConfig;
