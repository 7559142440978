import React, { ReactElement } from 'react';
import { Typography, Link } from '@interdan/public-ui';

import texts from '^configs/texts';
import { emitPrivacyPolicyLinkClick } from '^utils/gtmEvents';

interface IPermissionNoticeProps {
  className?: string;
}

const { privacyPolicyUrl } = window.xprops;
const { privacyPolicy, personalDataLink } = texts.supportPage.permissionNotice;

const PermissionNotice = ({ className }: IPermissionNoticeProps): ReactElement | null => (
  <Typography
    variant="caption1"
    className={className}
  >
    {privacyPolicy()}

    {' '}

    <Link
      href={privacyPolicyUrl}
      target="_blank"
      size="small"
      onClick={emitPrivacyPolicyLinkClick}
    >
      {personalDataLink}
    </Link>
  </Typography>
);

export default PermissionNotice;
