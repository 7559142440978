if (!window.xprops) {
  window.xprops = {
    privacyPolicyUrl: 'https://policies.google.com/privacy',
    theme: 'peugeot',
    selector: '',
    environment: 'development',
    fixedElementsSelectors: [],
    isWidgetMode: false,
    parentUrl: window.location.href,
    autoCalculateSpacers: (): Promise<undefined> => Promise.resolve(undefined),
    scrollTo: async (options: ScrollToOptions): Promise<void> => window.scrollTo({
      ...options,
      behavior: 'smooth',
    }),
    scrollToWidget: async (): Promise<void> => window.scrollTo({
      top: 0,
      behavior: 'smooth',
    }),
  };
}

export {};
