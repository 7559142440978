import React, { ReactElement } from 'react';
import { Alert } from '@interdan/public-ui';

import texts from '^configs/texts';
import ErrorReason from '^enums/ErrorReason';

interface IStatusProps {
  className?: string;
  reason?: ErrorReason;
  dataQa?: string;
}

const Status = ({ reason, className, dataQa }: IStatusProps): ReactElement | null => (
  <Alert
    severity="warning"
    className={className}
    data-qa={dataQa}
  >
    {reason ? texts.errorReason[reason] : texts.errors.default}
  </Alert>
);

export default Status;
