import { StrictMode } from 'react';
import { render } from 'react-dom';

import '^zoid-definitions/mockXprops';
import logger from '^utils/logger';
import gtmHelper from '^utils/gtmHelper';

import App from './App';

import '^scss-global';

logger.init();
gtmHelper.init();

render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root'),
);
