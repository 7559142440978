import { ReactElement } from 'react';
import { IIconProps } from '@interdan/public-ui';

const ErrorIcon = (props: IIconProps): ReactElement => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      r="11"
      transform="matrix(1 0 0 -1 12 12)"
      fill="currentColor"
    />

    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 18H11V16H13V18ZM13 14H11V6H13V14Z"
      fill="white"
    />
  </svg>
);

export default ErrorIcon;
