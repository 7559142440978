import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';
import { stringify } from 'query-string';

import { API_URL as baseURL } from '^configs/environments';

const createBaseClient = (config?: AxiosRequestConfig): AxiosInstance => axios.create({
  baseURL,
  ...config,
  // `any` is used here due to lack of typing in axios
  paramsSerializer: (params: any): string => (
    stringify(params, {
      arrayFormat: 'none',
    })
  ),
});

const apiClient = createBaseClient();

const zipBaseURL = 'https://api.zippopotam.us/DK';

const createZipClient = (): AxiosInstance => axios.create({
  baseURL: zipBaseURL,
});

export const zipCodesClient = createZipClient();

export default apiClient;
