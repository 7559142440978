enum StepKey {
  CarDetailsStep = 'carInfo',
  SelectStoreStep = 'store',
  SelectServicesStep = 'services',
  SelectAccessoriesStep = 'accessories',
  AppointmentStep = 'appointment',
  PersonalInformationStep = 'customerForm',
  OrderStep = 'confirmation',
}

export default StepKey;
