import { useLocation } from 'react-router-dom';
import { parse } from 'query-string';

const useQueryParams = <QueryParams>(): QueryParams => {
  const { search } = useLocation();

  return parse(search.replace('?', ''), {
    parseBooleans: true,
  }) as unknown as QueryParams;
};

export default useQueryParams;
