enum AutoCompleteValue {
  FirstName = 'given-name',
  LastName = 'family-name',
  CompanyName = 'organization',
  Email = 'email',
  Phone = 'tel',
  Zip = 'postal-code',
  City = 'address-level2',
  Address = 'street-address',
}

export default AutoCompleteValue;
