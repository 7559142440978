import { ReactElement } from 'react';
import { IIconProps } from '@interdan/public-ui';

const CheckmarkIcon = (props: IIconProps): ReactElement => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 12.8014L6.46796 11.3284L10.1716 15.0541L18.8249 6.37868L20.2929 7.8516L10.1716 18L5 12.8014Z"
      fill="currentColor"
    />
  </svg>
);

export default CheckmarkIcon;
