import { ReactElement, lazy, Suspense } from 'react';
import { Switch, Router, Route, Redirect } from 'react-router-dom';

import ILazyComponent from '^types/common/ILazyComponent';
import {
  getEntryPointPageRoutePath, getEntryPointPageRouteUrl, getErrorRoutePath,
  getMainFlowPageRoutePath, getSuccessRoutePath, getSupportPageRoutePath,
} from '^utils/routingHelpers';
import sharedHistory from '^sharedHistory';
import ErrorPage from '^components/ErrorPage';
import PageSkeleton from '^components/PageSkeleton';
import SuccessPage from '^components/SuccessPage';
import SupportPage from '^pages/SupportPage';

const EntryPoint = lazy((): Promise<ILazyComponent> => import('^pages/EntryPoint'));
const MainFlow = lazy((): Promise<ILazyComponent> => import('^pages/MainFlow'));

const { plateNumber } = window.xprops;

const redirectUrl = plateNumber
  ? getEntryPointPageRouteUrl({
    plateNumber,
  })
  : getEntryPointPageRoutePath();

const RoutingRules = (): ReactElement => (
  <Suspense fallback={<PageSkeleton />}>
    <Router history={sharedHistory}>
      <Switch>
        <Route
          component={EntryPoint}
          path={getEntryPointPageRoutePath()}
          exact
        />

        <Route
          component={MainFlow}
          path={getMainFlowPageRoutePath()}
          exact
        />

        <Route
          path={getErrorRoutePath()}
          component={ErrorPage}
          exact
        />

        <Route
          path={getSuccessRoutePath()}
          component={SuccessPage}
          exact
        />

        <Route
          path={getSupportPageRoutePath()}
          component={SupportPage}
          exact
        />

        <Redirect to={redirectUrl} />
      </Switch>
    </Router>
  </Suspense>
);

export default RoutingRules;

