import StepKey from '^enums/StepKey';

const dataQa = {
  plateNumberField: 'plate-number-field',
  searchButton: 'search-button',
  goBack: 'back-button',
  support: {
    warning: 'support-warning',
    name: 'support-name',
    surname: 'support-surname',
    email: 'support-email',
    phone: 'support-phone',
    message: 'support-message',
    submit: 'support-submit',
  },
  success: {
    title: 'success-title',
    logo: 'success-logo',
    description: 'success-description',
  },
  mainFlow: {
    [StepKey.CarDetailsStep]: {
      title: 'carInfo-title',
      fuelTypeLabel: 'carInfo-fuel-type-label',
      fuelType: 'carInfo-fuel-type',
      gearType: 'carInfo-gear-type',
      gearTypeLabel: 'carInfo-gear-type-label',
      odometer: 'carInfo-odometer',
      submit: 'carInfo-submit',
      container: 'carInfo-container',
    },
    [StepKey.SelectStoreStep]: {
      title: 'selectStore-title',
      container: 'selectStore-container',
    },
    [StepKey.SelectServicesStep]: {
      title: 'selectService-title',
      container: 'selectService-container',
      cardTitle: (id: number): string => `selectService-card-title-${id}`,
      cardCampaignLabel: (id: number): string => `selectService-card-campaign-label-${id}`,
      cardDescription: (id: number): string => `selectService-card-description-${id}`,
      cardPrice: (id: number): string => `selectService-card-price-${id}`,
      cardOldPrice: (id: number): string => `selectService-card-old-price-${id}`,
      selectCardButton: (id: number): string => `selectService-card-select-button-${id}`,
      submit: 'selectService-submit',
      cardContainer: (id: number): string => `selectService-card-container-${id}`,
      customCardContainer: 'selectService-card-container-custom',
      customCardButton: 'selectService-card-button-custom',
      total: 'selectService-total',
    },
    [StepKey.SelectAccessoriesStep]: {
      title: 'selectAccessories-title',
      container: 'selectAccessories-container',
      cardContainer: (id: number): string => `selectAccessories-card-container-${id}`,
      cardTitle: (id: number): string => `selectAccessories-card-title-${id}`,
      cardDescription: (id: number): string => `selectAccessories-card-description-${id}`,
      cardButton: (id: number): string => `selectAccessories-card-button-${id}`,
      cardPrice: (id: number): string => `selectAccessories-card-price-${id}`,
      total: 'selectAccessories-total',
      submit: 'selectAccessories-submit',
    },
    [StepKey.AppointmentStep]: {
      title: 'appointmentStep-title',
      container: 'appointmentStep-container',
      features: 'appointmentStep-feature-',
      activeDate: 'appointmentStep-active-date',
      submit: 'appointmentStep-submit',
    },
    [StepKey.PersonalInformationStep]: {
      title: 'personalInformation-title',
      container: 'personalInformation-container',
      submit: 'personalInformation-submit',

      name: 'personalInformation-name',
      surname: 'personalInformation-surname',
      email: 'personalInformation-email',
      phone: 'personalInformation-phone',
      message: 'personalInformation-message',
      city: 'personalInformation-city',
      zip: 'personalInformation-zip',
      address: 'personalInformation-address',
    },

    [StepKey.OrderStep]: {
      title: 'orderStep-title',
      container: 'orderStep-container',
      submit: 'orderStep-submit',
      message: 'orderStep-message',
      totalPrice: 'orderStep-totalPrice',
      sectionTitle: (step: StepKey): string => `orderStep-section-title-${step}`,
      sectionHeader: (step: StepKey): string => `orderStep-section-header-${step}`,
    },
  },
};

export default dataQa;
