/* eslint-disable max-lines */
import { formatNumber } from '@interdan/public-ui';

import StepKey from '^enums/StepKey';
import ErrorReason from '^enums/ErrorReason';
import PriceLabel from '^enums/PriceLabel';
import FuelType from '^enums/car/FuelType';
import GearType from '^enums/car/GearType';
import ServiceDisabledReason from '^enums/service/ServiceDisabledReason';
import AccessoryDisabledReason from '^enums/accessory/AccessoryDisabledReason';
import { MAX_SELECTED_SERVICES, MAX_SELECTED_ACCESSORIES } from '^configs/common';
import Feature from '^enums/Feature';

const texts = {
  units: {
    kilometers: 'km',
  },
  moneyUnits: {
    default: 'kr.',
  },
  common: {
    goBack: 'Tilbage',
    errorPage: {
      title: 'Noget gik galt',
      description: 'Prøv igen senere',
    },
    successPage: {
      title: 'Succes',
      description: 'Din besked er blevet sendt. Vi vil kontakte dig snart',
    },
    prebookingFlowAlert: 'Din ordre er en forespørgsel. Vi vil ringe til dig og bekræfte dato og tidspunkt efter bookingen.',
    unknownVariantAlert: 'OBS! Din bilmodel er ikke i vores systemer. For at få den endelige pris, bedes du tage kontakt til værkstedet.',
  },
  actions: {
    submit: 'Indsend',
    change: 'Ændre',
    proceed: 'Fortsæt',
    confirmBooking: 'Bekræft booking',
  },
  controls: {
    button: {
      change: 'Ændre',
    },
  },
  validationErrors: {
    required: 'Udfyld venligst feltet',
    plateNumber: 'Forkert nummerplade',
    maxChars: (value: number): string => `Du kan maks. indtaste ${value} tegn`,
    max: (value: number): string => `Maks ${formatNumber(value)}`,
    min: (value: number): string => `Min ${formatNumber(value)}`,
    email: 'Forkert email',
    phone: 'Forkert telefonnummer',
    zip: 'Forkert postnummer',
  },
  errors: {
    default: 'Noget gik galt',
  },
  entryPoint: {
    title: 'Nummerplade',
  },
  fuelTypes: {
    [FuelType.Diesel]: 'Diesel',
    [FuelType.Petrol]: 'Benzin',
    [FuelType.Hybrid]: 'Plug-in hybrid',
    [FuelType.Electric]: 'El',
    [FuelType.MildHybrid]: 'Mild hybrid',
  },
  gearTypes: {
    [GearType.Automatic]: 'Automatisk',
    [GearType.Manual]: 'Manuelt',
  },
  steps: {
    [StepKey.CarDetailsStep]: {
      title: 'Din bil',
      selectionBoxLabel: 'Bilinformation',
      fuelTypeLabel: 'Brændstof',
      gearBoxLabel: 'Geartype',
      odometerLabel: 'Antal kørte kilometer, km',
    },
    [StepKey.SelectStoreStep]: {
      title: 'Vælg værksted',
      valueSelectedTitle: 'Valgt værksted',
    },
    [StepKey.SelectServicesStep]: {
      title: 'Vælg service',
      selectedTitle: 'Valgt service',
      servicesCount: (count: number): string => `${count} ${count > 1 ? 'services' : 'service'}`,
      noServices: 'Ingen service valgt',
      selected: 'Fjern',
      remove: 'Beskriv',
      pricePrefix: 'Fra',
      lowerCasePricePrefix: 'fra',
    },
    [StepKey.SelectAccessoriesStep]: {
      title: 'Populært tilvalg',
      selectedTitle: 'Valgt tilbehør',
      accessoriesCount: (count: number): string => `${count} Tilbehør`,
      noAccessories: 'Intet tilbehør valgt',
    },
    [StepKey.AppointmentStep]: {
      title: 'Dato & tid',
      featurePicker: {
        title: 'Afhentningsmuligheder',
        valueSelectedTitle: 'Vælg afhentningsmulighed',
      },
      datePicker: {
        title: 'Ønsket dato',
        valueSelectedTitle: 'Dato',
      },
      timeSlotPicker: {
        title: 'Ønsket tidspunkt',
        valueSelectedTitle: 'Tidspunkt',
      },
    },
    [StepKey.PersonalInformationStep]: {
      title: 'Kontaktinformation',
      privacyPolicy: (): string => (
        `Ved at klikke "${texts.actions.proceed.toUpperCase()}" bekræfter jeg, at jeg er indforstået med behandlingen af mine persondata i henhold til følgende `
      ),
      clientPrivacyPolicy: ' & det valgte værksteds ',
      personalDataLink: 'persondatapolitik',
    },
    [StepKey.OrderStep]: {
      title: 'Din ordreinformation',
      sections: {
        car: 'Din bil',
        store: 'Værksted',
        services: 'Service',
        accessories: 'Tilbehør',
        dateAndTime: 'Dato & tid',
        personalInfo: 'Kontaktinformation',
      },
      customService: {
        afterEstimate: 'Pris gives af værksted',
      },
      message: {
        label: 'Besked til værksted',
      },
      disclaimer: 'Vær opmærksom på at prisen er vejledende, og kan blive ændret af værkstedet i tilfælde af uforudsete komplikationer. Der tages forbehold for prisændringer og trykfejl.',
      totalPrice: 'Total pris fra',
    },
  },
  featureOptions: {
    [Feature.WaitAtServicePoint]: {
      title: 'Jeg ønsker at vente imens',
      notice: 'Kontakt venligst værkstedet, såfremt du ønsker et tidsestimat for dit værkstedsbesøg.',
    },
    [Feature.PickupLater]: {
      title: 'Jeg henter bilen senere på dagen',
      notice: 'Du er velkommen til at kontakte værkstedet og få oplyst en ventetid',
    },
    [Feature.RentCar]: {
      title: 'Jeg ønsker en lejebil',
      notice: 'Der kan være ekstra omkostninger forbundet med valg af lejebil',
    },
  },
  errorReason: {
    [ErrorReason.CarNotFound]: 'Vi beklager, men bilen er ikke fundet. Kontakt os venligst.',
    [ErrorReason.NoAvailableStores]: 'Det ønskede værksted kunne desværre ikke findes',
  },
  services: {
    customService: 'Anden service eller reparation',
    serviceCard: {
      customService: {
        description: 'Er skaden sket? Eller kan du ikke finde en Servicepakke der dækker din bils behov? Så vælg denne Service og skriv din kommentar i feltet. Bemærk prisen kan variere ift. bilens alder, km. stand mm',
        field: {
          label: 'Beskrivelse',
        },
      },
      disabledReason: {
        [ServiceDisabledReason.MaxExceeded]: `Du kan vælge ${MAX_SELECTED_SERVICES} forskellige service`,
        [ServiceDisabledReason.Included]: 'Inkluderet i andre valgte service',
      },
    },
  },
  accessories: {
    disabledReason: {
      [AccessoryDisabledReason.MaxExceeded]: `Du kan vælge ${MAX_SELECTED_ACCESSORIES} forskellige tilbehør`,
    },
  },
  priceLabel: {
    [PriceLabel.From]: 'Fra',
    [PriceLabel.FixedPrice]: 'Fast pris',
    [PriceLabel.Max]: 'Maks',
    [PriceLabel.Free]: 'Gratis',
  },
  customer: {
    firstName: 'Fornavn',
    lastName: 'Efternavn',
    phone: 'Telefon',
    email: 'E-mail',
    address: 'Adresse',
    zip: 'Postnr.',
    city: 'By',
  },
  supportPage: {
    permissionNotice: {
      privacyPolicy: (): string => `Ved at klikke "${texts.actions.submit.toUpperCase()}" bekræfter jeg, at jeg er indforstået med behandlingen af mine persondata i henhold til følgende`,
      personalDataLink: 'persondatapolitik',
    },
    message: 'Besked til forhandler',
  },
};

export default texts;
