import { ReactElement, ReactNode } from 'react';
import { Typography } from '@interdan/public-ui';
import classNames from 'classnames';

import ContentWrapper from '^components/ContentWrapper';

import styles from './styles.module.scss';

export interface IResponsePageTemplateProps {
  Logo: ReactNode;
  title: ReactNode;
  description?: ReactNode;
  className?: string;
  logoWrapperClassName?: string;
  dataQaLogo?: string;
  dataQaDescription?: string;
  dataQaTitle?: string;
}

const ResponsePageTemplate = ({ dataQaTitle, dataQaDescription, dataQaLogo, className, logoWrapperClassName, Logo, title, description }: IResponsePageTemplateProps): ReactElement => (
  <ContentWrapper className={classNames(styles.responsePage, className)}>
    <div
      className={classNames(styles.logoWrapper, logoWrapperClassName)}
      data-qa={dataQaLogo}
    >
      {Logo}
    </div>

    <Typography
      variant="subtitle1"
      className={styles.title}
      component="div"
      data-qa={dataQaTitle}
    >
      {title}
    </Typography>

    {!!description && (
      <Typography
        className={styles.description}
        variant="body1"
        component="div"
        data-qa={dataQaDescription}
      >
        {description}
      </Typography>
    )}
  </ContentWrapper>
);

export default ResponsePageTemplate;
