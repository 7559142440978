import { arrayToMap } from '@interdan/public-ui';

import IStore from '^types/store/IStore';

const filterStoresList = (storesList: IStore[], storeCodes: IStore['code'][]): IStore[] => {
  if (storeCodes.length === 0) {
    return storesList;
  }

  const storesMap: Record<IStore['code'], IStore> = arrayToMap(storesList, 'code');
  const uniqueStoreCodes = [...new Set(storeCodes)];

  const filteredStoresList: IStore[] = [];

  uniqueStoreCodes.forEach((code: IStore['code']): void => {
    const store = storesMap[code];

    if (store) {
      filteredStoresList.push(store);
    }
  });

  return filteredStoresList;
};

export default filterStoresList;
