import { useEffect, useState } from 'react';

import StoresService from '^services/StoresService';
import { emitPredefinedStoreCodesNotAvailable } from '^utils/gtmEvents';
import { goToSupportPageRoute } from '^utils/routingHelpers';
import ErrorReason from '^enums/ErrorReason';
import { requestStoresList } from '^api/stores';
import logger from '^utils/logger';
import ConfigurationService from '^services/ConfigurationService';

import filterStoresList from './filterStoresList';

const predefinedStoreCodes = window.xprops.storeCodes || [];

const useRequestStores = (): boolean => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(
    (): void => {
      const request = async (): Promise<void> => {
        try {
          const { data: { list } } = await requestStoresList();

          if (predefinedStoreCodes.length === 0) {
            StoresService.setStoresList(list);

            return;
          }

          const filteredList = filterStoresList(list, predefinedStoreCodes);

          if (filteredList.length === 0) {
            emitPredefinedStoreCodesNotAvailable(predefinedStoreCodes.join());

            goToSupportPageRoute({
              reason: ErrorReason.NoAvailableStores,
              isBackLinkDisabled: true,
            });

            return;
          }

          if (filteredList.length === 1) {
            const [{ code }] = filteredList;
            ConfigurationService.setIsPredefinedStoreCode(true);
            ConfigurationService.setStoreCode(code);
          }

          StoresService.setPredefinedStoresList(filteredList);
        } catch (err) {
          logger.trackError(err, {
            catcher: 'useRequestStores',
          });
        } finally {
          setIsLoaded(true);
        }
      };

      void request();
    },
    [],
  );

  return isLoaded;
};

export default useRequestStores;
