import { LookupType, StoreSelectionMode, LookupRequestError } from '@interdan/store-selector';

import FuelType from '^enums/car/FuelType';
import GTMEventAction from '^enums/gtm/GTMEventAction';
import GTMEventCategory from '^enums/gtm/GTMEventCategory';
import GearType from '^enums/car/GearType';
import IGlobalService from '^types/service/IGlobalService';
import ILocalService from '^types/service/ILocalService';
import IAccessory from '^types/accessory/IAccessory';
import Feature from '^enums/Feature';
import StepKey from '^enums/StepKey';
import ErrorReason from '^enums/ErrorReason';

import gtmHelper from './gtmHelper';

const { privacyPolicyUrl, clientPrivacyPolicyUrl } = window.xprops;

export const emitCarSearch = (plateNumber: string): void =>
  gtmHelper.emitEvent(GTMEventCategory.CarSearchForm, GTMEventAction.Submit, plateNumber);

export const emitCarSearchFailed = (plateNumber: string): void =>
  gtmHelper.emitEvent(GTMEventCategory.CarSearchForm, GTMEventAction.Error, plateNumber);

export const emitPredefinedCarSearch = (plateNumber: string): void =>
  gtmHelper.emitEvent(GTMEventCategory.CarSearchFromQueryParams, GTMEventAction.Submit, plateNumber);

export const emitPredefinedCarSearchFailed = (plateNumber: string): void =>
  gtmHelper.emitEvent(GTMEventCategory.CarSearchFromQueryParams, GTMEventAction.Error, plateNumber);

export const emitCarChange = (): void =>
  gtmHelper.emitEvent(GTMEventCategory.CarSearchForm, GTMEventAction.Edit);

export const emitFuelTypeClick = (fuelType: FuelType): void =>
  gtmHelper.emitEvent(GTMEventCategory.CarFuelType, GTMEventAction.Click, fuelType);

export const emitGearTypeClick = (gearType: GearType): void =>
  gtmHelper.emitEvent(GTMEventCategory.CarGearType, GTMEventAction.Click, gearType);

export const emitCarInfoFormSubmit = (): void =>
  gtmHelper.emitEvent(GTMEventCategory.CarInfoForm, GTMEventAction.Submit);

export const emitStoreLookup = (lookupType: LookupType, zip?: string): void => {
  const label = lookupType === LookupType.Zip ? `${LookupType.Zip}, ${zip}` : lookupType;

  gtmHelper.emitEvent(GTMEventCategory.StoreSearch, GTMEventAction.Click, label);
};

export const emitStoreLookupReset = (): void =>
  gtmHelper.emitEvent(GTMEventCategory.StoreSearch, GTMEventAction.Reset);

export const emitNoStoresFound = (lookupType: LookupType, radius: number): void => {
  const label = `${lookupType}, ${radius}`;
  gtmHelper.emitEvent(GTMEventCategory.StoreSearch, GTMEventAction.NoResults, label);
};

export const emitStoreSearchLocationRequestFailed = (error: LookupRequestError): void =>
  gtmHelper.emitEvent(GTMEventCategory.StoreSearchLocationRequest, GTMEventAction.Error, error);

export const emitStoreSearchZipRequestFailed = (zip: string): void =>
  gtmHelper.emitEvent(GTMEventCategory.StoreSearchZipRequest, GTMEventAction.Error, zip);

export const emitSearchRadiusChange = (radius: number): void =>
  gtmHelper.emitEvent(GTMEventCategory.StoreSearchRadius, GTMEventAction.Change, radius.toString());

export const emitStoresMapPinClick = (): void =>
  gtmHelper.emitEvent(GTMEventCategory.StoresMapPin, GTMEventAction.Click);

export const emitStoreSelect = (selectionMode: StoreSelectionMode): void =>
  gtmHelper.emitEvent(GTMEventCategory.StoreForm, GTMEventAction.Submit, selectionMode);

export const emitStoreChangeClick = (): void =>
  gtmHelper.emitEvent(GTMEventCategory.StoreForm, GTMEventAction.Edit);

export const emitToggleGlobalService = (isSelected: boolean, serviceName: IGlobalService['name']): void => (
  gtmHelper.emitEvent(
    GTMEventCategory.GlobalServices,
    isSelected
      ? GTMEventAction.Add
      : GTMEventAction.Remove,
    serviceName,
  )
);

export const emitToggleLocalService = (isSelected: boolean, serviceName: ILocalService['name']): void => (
  gtmHelper.emitEvent(
    GTMEventCategory.LocalServices,
    isSelected
      ? GTMEventAction.Add
      : GTMEventAction.Remove,
    serviceName,
  )
);

export const emitToggleCustomService = (isSelected: boolean): void => (
  gtmHelper.emitEvent(
    GTMEventCategory.CustomService,
    isSelected
      ? GTMEventAction.Add
      : GTMEventAction.Remove,
  )
);

export const emitServicesStepProceedButtonClick = (): void =>
  gtmHelper.emitEvent(GTMEventCategory.Services, GTMEventAction.Submit);

type TAccessoryCategory = (
  GTMEventCategory.AftermarketAccessories
  | GTMEventCategory.GlobalAccessories
  | GTMEventCategory.LocalAccessories
);

const emitToggleAccessory = (category: TAccessoryCategory) => (isSelected: boolean, { name }: IAccessory): void => (
  gtmHelper.emitEvent(
    category,
    isSelected
      ? GTMEventAction.Add
      : GTMEventAction.Remove,
    name,
  )
);

export const emitToggleAftermarketAccessory = emitToggleAccessory(GTMEventCategory.AftermarketAccessories);

export const emitToggleGlobalAccessory = emitToggleAccessory(GTMEventCategory.GlobalAccessories);

export const emitToggleLocalAccessory = emitToggleAccessory(GTMEventCategory.LocalAccessories);

export const emitAccessoriesStepProceedButtonClick = (): void =>
  gtmHelper.emitEvent(GTMEventCategory.Accessories, GTMEventAction.Submit);

export const emitFeatureSelect = (feature: Feature): void =>
  gtmHelper.emitEvent(GTMEventCategory.AppointmentFeature, GTMEventAction.Select, feature);

export const emitFeatureChangeClick = (): void =>
  gtmHelper.emitEvent(GTMEventCategory.AppointmentFeature, GTMEventAction.Edit);

export const emitDateSelect = (date: string): void =>
  gtmHelper.emitEvent(GTMEventCategory.AppointmentDate, GTMEventAction.Select, date);

export const emitDateChangeClick = (): void =>
  gtmHelper.emitEvent(GTMEventCategory.AppointmentDate, GTMEventAction.Edit);

export const emitTimeSlotSelect = (timeSlot: string): void =>
  gtmHelper.emitEvent(GTMEventCategory.AppointmentTimeslot, GTMEventAction.Select, timeSlot);

export const emitTimeSlotChangeClick = (): void =>
  gtmHelper.emitEvent(GTMEventCategory.AppointmentTimeslot, GTMEventAction.Edit);

export const emitAppointmentFormSubmit = (): void =>
  gtmHelper.emitEvent(GTMEventCategory.AppointmentForm, GTMEventAction.Submit);

export const emitPrivacyPolicyLinkClick = (): void =>
  gtmHelper.emitEvent(GTMEventCategory.PrivacyPolicyLink, GTMEventAction.Click, privacyPolicyUrl);

export const emitClientPrivacyPolicyLinkClick = (): void =>
  gtmHelper.emitEvent(GTMEventCategory.ClientPrivacyPolicyLink, GTMEventAction.Click, clientPrivacyPolicyUrl);

export const emitCustomerFormSubmit = (): void =>
  gtmHelper.emitEvent(GTMEventCategory.CustomerForm, GTMEventAction.Submit);

export const emitBookingSubmit = (): void =>
  gtmHelper.emitEvent(GTMEventCategory.Booking, GTMEventAction.Submit);

export const emitBookingSubmissionFailed = (): void =>
  gtmHelper.emitEvent(GTMEventCategory.Booking, GTMEventAction.Error);

export const emitSupportFormSubmit = (reason: ErrorReason): void =>
  gtmHelper.emitEvent(GTMEventCategory.SupportForm, GTMEventAction.Submit, reason);

export const emitSupportFormSubmissionFailed = (): void =>
  gtmHelper.emitEvent(GTMEventCategory.SupportForm, GTMEventAction.Error);

export const emitStepChangeClick = (stepKey: StepKey): void =>
  gtmHelper.emitEvent(GTMEventCategory.Step, GTMEventAction.Edit, stepKey);

export const emitConfirmationStepChangeClick = (stepKey: StepKey): void =>
  gtmHelper.emitEvent(GTMEventCategory.ConfirmationStep, GTMEventAction.Edit, stepKey);

export const emitToggleAccordion = (isOpen: boolean, accordionKey: string): void => (
  gtmHelper.emitEvent(
    GTMEventCategory.Accordion,
    isOpen ? GTMEventAction.Open : GTMEventAction.Close,
    accordionKey,
  )
);

export const emitPredefinedStoreCodesNotAvailable = (storeCodes: string): void =>
  gtmHelper.emitEvent(GTMEventCategory.PredefinedStoreCodes, GTMEventAction.Error, storeCodes);
