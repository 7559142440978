import { observable } from 'micro-observables';
import { arrayToMap } from '@interdan/public-ui';

import IStore from '^types/store/IStore';
import ICar from '^types/car/ICar';

type TStoresMap = Record<IStore['code'], IStore>;

class StoresService {
  private _storesList = observable<IStore[]>([]);
  private _availableStores = observable<IStore[]>([]);
  private _isStoresListPredefined = observable<boolean>(false);

  public storesList = this._storesList.readOnly();
  public availableStores = this._availableStores.readOnly();

  public storesMap = this._storesList.select((list: IStore[]): TStoresMap => arrayToMap(list, 'code'));

  public filterStoresBasedOnCarBrand(brand: ICar['brand']): void {
    if (this._isStoresListPredefined.get()) {
      return;
    }

    const stores = this._storesList.get();
    const filteredAvailableStores = stores.filter((store: IStore): boolean => store.supportedBrands.includes(brand));
    this._availableStores.set(filteredAvailableStores);
  }

  public resetStores(): void {
    this._availableStores.set(this._storesList.get());
  }

  public setStoresList(stores: IStore[]): void {
    this._storesList.set(stores);
  }

  public setPredefinedStoresList(stores: IStore[]): void {
    this._storesList.set(stores);
    this._isStoresListPredefined.set(true);
  }

  public constructor() {
    this._storesList.subscribe((list: IStore[]): void => {
      this._availableStores.set(list);
    });
  }
}

export default new StoresService();
