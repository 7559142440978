import { AxiosResponse } from 'axios';

import IApiListResponse from '^types/api/IApiListResponse';
import IStore from '^types/store/IStore';

import apiClient from '.';

export type TRequestStoresResponse = AxiosResponse<IApiListResponse<IStore>>;

export const requestStoresList = (): Promise<TRequestStoresResponse> => (
  apiClient.get('/Stores')
);
