import { AnyMaskedOptions } from 'imask';

export const plateNumberMask: AnyMaskedOptions[] = [
  {
    mask: 'aa 00 000',
  },
  {
    mask: /^[a-zA-Z0-9]{0,7}$/,
  },
];

export const phoneNumberMask = '+45 00 00 00 00';
export const zipMask = '0000';
