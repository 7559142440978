import { ReactElement } from 'react';
import classNames from 'classnames';

import SkeletonBase, { ISkeletonBaseProps } from '^components/SkeletonBase';

import styles from './styles.module.scss';

export interface IPageSkeletonProps extends ISkeletonBaseProps {}

const PageSkeleton = ({ className, ...rest }: IPageSkeletonProps): ReactElement => (
  <SkeletonBase
    className={classNames(styles.skeleton, className)}
    {...rest}
  >
    <rect
      width="149"
      height="16"
      rx="4"
      fill="currentColor"
    />

    <rect
      y="32"
      width="100%"
      height="16"
      rx="4"
      fill="currentColor"
    />

    <rect
      y="64"
      width="100%"
      height="16"
      rx="4"
      fill="currentColor"
    />

    <rect
      y="112"
      width="149"
      height="16"
      rx="4"
      fill="currentColor"
    />

    <rect
      y="144"
      width="100%"
      height="16"
      rx="4"
      fill="currentColor"
    />

    <rect
      y="176"
      width="100%"
      height="16"
      rx="4"
      fill="currentColor"
    />

    <rect
      y="224"
      width="149"
      height="16"
      rx="4"
      fill="currentColor"
    />

    <rect
      y="256"
      width="100%"
      height="16"
      rx="4"
      fill="currentColor"
    />

    <rect
      y="288"
      width="100%"
      height="16"
      rx="4"
      fill="currentColor"
    />

    <rect
      y="336"
      width="149"
      height="16"
      rx="4"
      fill="currentColor"
    />

    <rect
      y="368"
      width="100%"
      height="16"
      rx="4"
      fill="currentColor"
    />

    <rect
      y="400"
      width="100%"
      height="16"
      rx="4"
      fill="currentColor"
    />

    <rect
      y="448"
      width="149"
      height="16"
      rx="4"
      fill="currentColor"
    />

    <rect
      y="480"
      width="100%"
      height="16"
      rx="4"
      fill="currentColor"
    />

    <rect
      y="512"
      width="100%"
      height="16"
      rx="4"
      fill="currentColor"
    />
  </SkeletonBase>
);

export default PageSkeleton;
