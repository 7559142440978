enum GTMEventCategory {
  CarSearchForm = 'car-search-form',
  CarSearchFromQueryParams = 'car-search-from-query-params',
  CarFuelType = 'car-fuel-type',
  CarGearType = 'car-gear-type',
  CarInfoForm = 'car-info-form',
  StoreSearch = 'store-search',
  StoreSearchLocationRequest = 'store-search-location-request',
  StoreSearchZipRequest = 'store-search-zip-request',
  StoreSearchRadius = 'store-search-radius',
  StoresMapPin = 'stores-map-pin',
  StoreForm = 'store-form',
  GlobalServices = 'global-services',
  LocalServices = 'local-services',
  CustomService = 'custom-service',
  Services = 'services',
  AftermarketAccessories = 'aftermarket-accessories',
  GlobalAccessories = 'global-accessories',
  LocalAccessories = 'local-accessories',
  Accessories = 'accessories',
  AppointmentFeature = 'appointment-feature',
  AppointmentDate = 'appointment-date',
  AppointmentTimeslot = 'appointment-time-slot',
  AppointmentForm = 'appointment-form',
  PrivacyPolicyLink = 'privacy-policy-link',
  ClientPrivacyPolicyLink = 'client-privacy-policy-link',
  CustomerForm = 'customer-form',
  Booking = 'booking',
  SupportForm = 'support-form',
  Step = 'step',
  ConfirmationStep = 'confirmation-step',
  Accordion = 'accordion',
  PredefinedStoreCodes = 'predefined-store-codes',
}

export default GTMEventCategory;
